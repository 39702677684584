import React, { useEffect } from 'react';

import Layout from '../components/Layout/Layout';
import RoleBasedRoute from '../components/RoleBasedRoute';
import { nonSellerRoles } from '../constans/roles';
import { getQueryParams } from '../helpers/helper-methods';
import CookiesService from '../services/cookie.service';
import SessionService from '../services/session.service';
import { COOKIES_KEYS, COOKIES_LIFETIME, SESSIONSTORAGE_KEY } from '../constans/localstorage';
import http from '../services/http.service';
import { API } from '../constans/http';
import styles from './search/index.module.scss';
import Laptop from '../components/Laptop';

function IndexPage({ location }) {
  const { search } = location;
  const { BP } = getQueryParams(search);
  const partnerNickname = CookiesService().getCookie(COOKIES_KEYS.PARTNER_NICKNAME);

  useEffect(() => {
    if (BP) {
      CookiesService().setCookie(
        COOKIES_KEYS.PARTNER_NICKNAME,
        BP,
        COOKIES_LIFETIME,
      );

      http.get(`${API.PARTNERS}/${BP}/logo`, { useToast: false })
        .then(({ data }) => {
          const { file } = data;
          if (file.link) {
            SessionService().set(SESSIONSTORAGE_KEY.PARTNER_LOGO, file.link);
            SessionService().set(SESSIONSTORAGE_KEY.LOGO_SUBTITLE, data.subTitle);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (partnerNickname && !BP) {
      http.get(`${API.PARTNERS}/${partnerNickname}/logo`, { useToast: false })
        .then(({ data }) => {
          const { file } = data;
          if (file.link) {
            SessionService().set(SESSIONSTORAGE_KEY.PARTNER_LOGO, file.link);
            SessionService().set(SESSIONSTORAGE_KEY.LOGO_SUBTITLE, data.subTitle);
          }
        });
    }
  }, []);

  return (
    <RoleBasedRoute
      component={() => (
        <Layout mainClasses={`${styles.block} section-padded-wrapper flex-grow-1`}>
          <div className={styles.container}>
            <Laptop />
          </div>
        </Layout>
      )}
      availableRoles={nonSellerRoles}
    />
  );
}

export default IndexPage;
