import React, { useEffect, useMemo, useRef, useState } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { Helmet } from 'react-helmet';
import styles from './index.module.scss';
import { FEATURES_TEXT } from '../../constans/texts/texts';
import Button from '../UI/Button/Button';
import {
  VideoEmulatorLaptop,
  VideoEmulatorMobile,
} from '../VideoImulator/VideoEmulator';
import ExploreTab from '../ExploreTab';
import customStyles from '../Home/index.module.scss';
import { PHONE_WIDTH_BREAKPOINT } from '../../constans/header';
import { useWindowWidth } from '../../hooks/useWindowWidth';

const desktopVideoLink =
  'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_94470bb6-40cd-4fb6-8a22-93a896e83960.mp4';
const mobileVideoLink =
  'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_73bcc5b2-bbef-4ee2-852e-7cf50d49f1c2.mp4';

function Laptop() {
  const containerRef = useRef();
  const lockRef = useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRef);
  const windowWidth = useWindowWidth();
  const isMobile = useMemo(() => windowWidth <= PHONE_WIDTH_BREAKPOINT, [
    windowWidth,
  ]);

  const [isShowDemo, setIsShowDemo] = useState(false);

  useEffect(() => {
    setIsShowDemo(!isMobile);
  }, [isMobile]);

  if (isIntersecting) {
    lockRef.current = true;
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <meta
          id="title"
          name="title"
          content="Koralgo, your trustworthy travel planning app"
        />
        <meta
          id="description"
          name="description"
          content="Our analysts do the research & write comprehensive reviews and our algos generate the best free personalize itineraries available on the web."
        />
        <meta
          id="meta-keywords"
          name="meta-keywords"
          content="activity reviews, hotel reviews, free travel itinerary, itinerary planning, trustworthy reviews, best travel planning app"
        />
      </Helmet>
      <ExploreTab styles={customStyles} />
      <div className={`${!isShowDemo ? styles.mt8 : styles.hidden}`} />

      <h1 className={styles.descriptionCentered}>
        Your trustworthy travel planning app
      </h1>
      <p className={styles.textDescription}>
        We have researched all activities, so you don’t have to. Just enter your
        search details to generate free itineraries and adjust “Your Interests”
        settings for more personalized results.
      </p>
      <div
        className={`${styles.containerVide} ${!isShowDemo && styles.hidden}`}
      >
        <div className={styles.block}>
          <VideoEmulatorLaptop
            imgWidth={814}
            imgHeight={420}
            videoWidth={650}
            videoHeight={420}
            link={desktopVideoLink}
          />
        </div>

        <VideoEmulatorMobile link={mobileVideoLink} />
      </div>
      <div
        className={`${styles.containerVide} ${isShowDemo && styles.mt6} ${
          styles.mb6
        }`}
      >
        <Button
          type="submit"
          onClick={() => {
            setIsShowDemo(!isShowDemo);
          }}
          classes={`${styles.submitButton} t-600 btn btn_primary-danger uppercased-text`}
        >
          {isShowDemo ? 'Hide demo' : 'Show demo'}
        </Button>
      </div>
      <div className={styles.listBlock}>
        <div className={styles.table}>
          {FEATURES_TEXT.map((item, index) => (
            <div className={styles.tableItemBlock} key={item.label}>
              <img
                src={item.icon}
                className={index === 1 ? styles.worldIcon : styles.image}
                alt=""
              />
              <h3>{item.label}</h3>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Laptop;
